<template lang="html">
  <div style="padding-bottom: 5rem;">
    <div class="d-flex justify-center animate__animated animate__zoomIn" style="font-size: 2.3rem; color: red;">
      Enquiries
    </div>
    <v-row class="no-gutters mb-3">
      <v-col class="col-12 d-flex justify-center align-center pa-4" style="border-right: solid 3px white;">
        Our team team will get back to through either of the following means, our default channel is through calls, but you may specify your preffered channel:
      </v-col>
      <v-col class="col-12 ml-4 pl-4">
        <h3 class="red--text d-flex align-center">
          <v-icon class="red rounded bold white--text mr-1" style="font-weigth: 800;">
            mdi-check
          </v-icon>
          Phone Call
        </h3> 
      </v-col>
      <v-col class="col-12 ml-4 pl-4">
        <h3 class="red--text d-flex align-center">
          <v-icon class="red rounded bold white--text mr-1" style="font-weigth: 800;">
            mdi-check
          </v-icon>
          SMS
        </h3>
      </v-col>
      <v-col class="col-12 ml-4 pl-4">
        <h3 class="red--text d-flex align-center">
          <v-icon class="red rounded bold white--text mr-1" style="font-weigth: 800;">
            mdi-check
          </v-icon>
          Email
        </h3>
      </v-col>
      <v-col class="col-12 d-flex justify-center">
        <v-form ref="form" class="" style="width: 90%;">
          <p class="text--center"> 
            Enter your enquiry below
          </p>
          <v-text-field
          placeholder="name"
          v-model="contact_data.name"
          label="name"
          type="name"
          outlined
          clearable />
          <v-text-field
          placeholder="email"
          v-model="contact_data.email"
          label="email"
          type="name"
          outlined
          clearable />
          <v-text-field
          placeholder="phone number"
          v-model="contact_data.phone_number"
          label="phone number"
          type="number"
          outlined
          clearable />
          <v-text-field
          placeholder="subject"
          v-model="contact_data.subject"
          label="subject"
          type="name"
          outlined
          clearable />
          <v-textarea
          placeholder="message"
          v-model="contact_data.message"
          label="message"
          type="name"
          outlined
          clearable />
          <div  class="d-flex justify-end">
            <v-btn class="success"> send </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'enquire',
  data(){
    return{
      contact_data: {}
    }
  }
}
</script>
